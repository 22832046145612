<template>
  <main>
    <h1 class="pt-15 pb-16">Dossier</h1>
    <h3>Photos</h3>
    <div>
      <v-file-input
        v-if="!rentalFile.photoUrl && !rentalFile.avatar"
        accept="image/png,image/jpeg"
        label="File input"
        v-model="photo"
        @change="addPhoto()"
      />
    </div>
    <img 
      v-show="rentalFile.photoUrl || rentalFile.avatar" 
      class="avatar" 
      :src="rentalFile.avatar ? BaseUrl + '/images/avatars/' + rentalFile.avatar : BaseUrl + rentalFile.photoUrl"
      @click="
        rentalFile.photoUrl 
        ? openPhotos(BaseUrl + rentalFile.photoUrl.replace(/@\d+x\d+/, '@1600x1200'))
        : ''
      "
    >
    <br>
    <v-btn
      v-if="rentalFile.photoUrl"
      class="error my-3"
      @click="deletePhoto()"
    >
      Supprimer la photo
    </v-btn>

    <form>
      <h2 class="pb-10" >A propos du dossier</h2>

      <v-select
        v-model="rentalFile.situation"
        :items="situations"
        item-text="label"
        item-value="id"
        label="Type"
      ></v-select>

      <v-textarea
        name="input-7-1"
        filled
        label="Message"
        class="w-5"
        v-model="rentalFile.bio"
      ></v-textarea>

      <a v-if="rentalFile.documentsUrl" :href="rentalFile.documentsUrl">Dossier facile : {{rentalFile.documentsUrl}}</a>
      <br>
      <br>
      <div v-for="(document, i) in rentalFile.documents" :key="i">
        <a v-if="document.url" :href="BaseUrl + document.url" target="blank">Documents justificatifs : {{BaseUrl + document.url}}</a> 
        <br>
        <v-btn
          class="error my-3"
          @click="deleteDocument(i)"
        >
         Supprimer le document
        </v-btn>
      </div>

      <p class="mt-4">Revenu des locataires : <strong>{{ totalIncomes }}</strong></p>

      <v-btn
        class="info"
        @click="submit()"
      >
        Valider
      </v-btn>
    </form>
    <br>
    <h1>Locataires</h1>
    <v-data-table
      :headers="tenantsHeaders"
      :items="tenants"
      :hide-default-footer="true"
      dense
      class="mb-10"
    >
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        target="_blank"
        @click="editItem(item.uid)"
      >
        mdi-pencil
      </v-icon>
    </template>
    </v-data-table>
  </main>
</template>

<script>
import axios from "axios";
import axiosGetListData from '@/apiCall/axiosGetList.js'


export default {
  data: () => ({
    BaseUrl: process.env.VUE_APP_BASEURL,
    rentalFile: {},
    tenants: [],
    situations: [],
    tenantsHeaders: [
      { text: 'Id', value: 'id', sortable: false},
      { text: 'Prénom', value: 'firstname', sortable: false},
      { text: 'Nom', value: 'lastname', sortable: false},
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    totalIncomes: 0,
    photo:{}
  }),
  mounted() {
    axios
      .get(
        process.env.VUE_APP_APIURL +
          "/rentalFiles/" +
          this.$route.params.rentalFileUid,
        {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            expand: 'tenants,documents'
          }
        }
      )
      .then((res) => {
        this.rentalFile = res.data;
        // let incomes = this.rentalFile.tenants.map(tenant =>  tenant.incomes1 + tenant.incomes2 + tenant.incomes3).reduce((a,b) => a + b ,0) /3

        // let unusualIncomes = this.rentalFile.tenants.map(tenant =>  tenant.unusualIncomes).reduce((a,b) => a + b ,0) / 12

        // let recurringIncomes = this.rentalFile.tenants.map(tenant => tenant.recurringIncomes.map(recurring => recurring.amount).reduce((a,b) => a + b / tenant.recurringIncomes.length ,0)).reduce((a,b) => a + b ,0)

        // this.totalIncomes = Math.round(incomes + unusualIncomes + recurringIncomes)
        this.totalIncomes = Math.round(this.rentalFile.tenants.map(tenant => tenant.incomes).reduce((a,b) => a+b,0) / this.rentalFile.tenantsCount) 

      });

    axios.get(process.env.VUE_APP_APIURL + "/rentalFilesTenants/",{
        headers: {
          "Access-Token": localStorage.getItem("token"),
        },
        params: {
          rentalFileUid: this.$route.params.rentalFileUid,
          sort: 'id ASC'
        },
      })
      .then((res) => {
        this.tenants = res.data;
      });

      axiosGetListData(this, '/rentalFilesSituations', 'situations')
  },

  methods: {
     
      openPhotos(url){
        window.open(url)
      },

      deleteDocument(index){
        const documentId = this.rentalFile.documents[index].uid
        if(confirm('Supprimer ce document ?')){
          if(documentId){

            axios.delete(process.env.VUE_APP_APIURL + '/rentalFilesDocuments/' + documentId, {
              headers: {
                "Access-Token": localStorage.getItem("token"),
              },
            }).then((res) => {
              if(res){
                this.rentalFile.documents.splice(index,1);
              }
            })
            
          }
        }
      },
      deletePhoto(){
        if(confirm('Supprimer cette photo de dossier ?')){
            axios.delete(process.env.VUE_APP_APIURL + '/rentalFilesPhotos/', {
              headers: {
                "Access-Token": localStorage.getItem("token"),
              },
              params:{
                rentalFileUid: this.$route.params.rentalFileUid,
              },
            }).then((res) => {
              if(res){
                this.rentalFile.photoUrl= ""
              }
            })
        }
      },
      addPhoto(){
        if (this.photo){
          axios.post(process.env.VUE_APP_APIURL + '/rentalFilesPhotos/', this.photo ,{
            headers: {
              'Access-Token': localStorage.getItem('token'),
            },
            params: {
              rentalFileUid: this.$route.params.rentalFileUid,
            },
          })
          .then(response => {
            if (response) {
              this.rentalFile.photoUrl = response.data.url
            }
          })
        } else {
          return
        }
      },
      submit(){
        axios.put(process.env.VUE_APP_APIURL + '/rentalFiles/' + this.$route.params.rentalFileUid, {
          situation: this.rentalFile.situation != null ? this.rentalFile.situation : undefined,
          bio: this.rentalFile.bio ? this.rentalFile.bio : '',
        },{
          headers :{
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            rentalFileUid: this.$route.params.fileUid,
          },
        })
        .then(res => {
						if ( res.status == 200 ) {
              document.location.reload();
            }
          })
          .catch(err => {
            console.log(err.response.data)
          })
      },

      editItem (uid) {
        open(this.$router.resolve({name: 'tenant', params: {tenantUid: uid}}).href, '_blank');
      },
  }
};
</script>

<style >
  main {
    max-width: 600px;
    margin: auto;
  }

  .avatar {
    height: 300px;
  }

  h2 {
    padding: 2rem 0px 2.2rem 0px;
    text-decoration: underline;
  }

</style>
